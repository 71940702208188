/*
 * Preloader CSS
 */

 .preloader{
 	position: fixed;
 	top: 0;
 	left: 0;
 	z-index: 999999;
 	width: 100%;
 	height: 100%;
 	background-color: #fff;
 	background-image: url(../../images/Preloader_7.gif);
 	background-repeat: no-repeat;
 	background-position: center center;
 }

 /*
  * Fancy checkbox
  */
 
 .form-group input[type="checkbox"] {
     display: none;
 }

 .form-group input[type="checkbox"] + .btn-group > label span {
     width: 20px;
 }

 .form-group input[type="checkbox"] + .btn-group > label span:first-child {
     display: none;
 }
 .form-group input[type="checkbox"] + .btn-group > label span:last-child {
     display: inline-block;   
 }

 .form-group input[type="checkbox"]:checked + .btn-group > label span:first-child {
     display: inline-block;
 }
 .form-group input[type="checkbox"]:checked + .btn-group > label span:last-child {
     display: none;   
 }