.flag {
    width: 20px !important;
    margin: 5px 2.5px;
    float: right;
}

ul.icon-flag {
    list-style-type: none;
    margin: 0 -20px 0 0;
    padding: 0;

    li {
        float: left;
    }

    li a {
        display: block;
        text-align: center;
        text-decoration: none;
    }
}

.mg-book-gray {
    color: #FFF;
    background-color: #333;
    border-top: 1px solid #666;
}

.mg-footer-custom {
    border-top: 1px solid #666;
}

.thumbnail-size {
    width: 100px;
}

.table > tbody > tr > td {
     vertical-align: middle;
}

.table-borderless td,
.table-borderless th {
    border: 0 !important;
}

table {
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    text-align: center;

    th {
        text-align: center;
    }

    td {
        padding: 10px 0 !important;
        border: 0 !important;
    }

    .price {
        color: #ff9427;

        span {
            font-size: 2rem;
            font-weight: 900;
        }
    }

    input[type="radio"],
    input[type="radio"]:checked{
        outline: 0 none !important;
    }

    i {
        color: #CCC;
    }
}

.required {
    label.label-required {
        color:                  darken($brand-danger, 20%);
    }

    //Custom fields
    .label-required:after { 
        content:                "*";
        margin-left:            5px;
        top:                    5px;
    }

    //Required fields
    input:required,
    select:required,
    textarea:required {
        border: 1px solid $brand-danger !important;
    }

    select:required, 
    select:disabled {
        font-size: 1.5rem;
        height: 38px !important;
    }
}

legend {
    background-color: $brand-yellow;
    border-bottom: 1px solid $brand-warning;
    color: #666;
    font-weight: 600;
    padding: 5px;
}

.carousel-caption {
    background:rgba(0, 0, 0, 0.5);
}

.summary{
    h3 {
        font-weight: 900;
        border-bottom: 1px solid #CCC;
        color: #999;
        margin-bottom: 15px;
    }

    h5 {
        color: #666;
    }

    .item {
        .title {
            color: $palette-gray;
        }
        .text {
            font-weight: 600;
        }
    }
} 

.loading {    
    background-color: #ffffff;
    background-image: url("../../../../img/loading.gif");
    background-size: 25px 25px;
    background-position: center center;
    background-repeat: no-repeat;
}

ul#payment {
    margin: 10px;
    list-style-type: none;

    input[type="radio"],
    input[type="radio"]:checked{
        outline: 0 none !important;
    }

    li {
        margin: 15px;
        font-weight: 700;
    }
}

.time, 
.time > div {
    margin: 0;
    padding: 0;
}

.time > .first {
    padding-right: 6px;
}

.info {
    background: #000;
    color: #999;
    padding: 5px;
    margin: 0;
}