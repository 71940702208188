// Variables
@import "mixins";
@import "variables";

// Bootstrap
@import "vendor/bootstrap/bootstrap.min.css";
@import "vendor/bootstrap/font-awesome.min.css";

// Components 
@import "sections/bootstrap-datepicker3";
@import "sections/bootstrap";
@import "sections/selector";
@import "sections/freepik.hotels";
@import "sections/owl.carousel";
@import "sections/owl.theme";
@import "sections/owl.transitions";
@import "sections/style";
@import "sections/custom";
