//Example of use
// input {  
//     @include input-placeholder {
//         color: $grey;
//     }
// }
//input-placeholder color
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

///////////////////////////////////

//Example of use
// p {
//   @include font-size(14px)
// }
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

///////////////////////////////////

//Example of use
// a {
//   color: gray;
//   @include transition(color .3s ease);
//   &:hover {
//     color: black;
//   }
// }

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

///////////////////////////////////

//Example of use
// .faded-text {
//   @include opacity(0.8);
// }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


///////////////////////////////////

//Example of use
// @include border-radius(6px);

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

///////////////////////////////////

//Example of use
// @include background-opacity(#333, 0.5);

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}