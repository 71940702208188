//General Colors 
$color-black: #222;
$color-white: #FFF;
$color-gray-light: #F7F7F7;

// Brands
$brand-primary: #0275D8;
$brand-primary-dark: #0d47a1;
$brand-info: #5BC0DE;
$brand-info-dark: #0099CC;
$brand-success: #449D44;
$brand-success-dark: #007E33;
$brand-warning: #F0AD4E;
$brand-warning-dark: #ffbb33;
$brand-danger: #D9534F;
$brand-danger-dark: #CC0000;
$brand-yellow: #fcf8e3;

//Base Colors
$palette-orange: #FF9427;
$palette-gray: #999;
$palette-blue: #0085cf;

//Navbar 
$navbar-height: 64px;
